import CryptoJS from 'crypto-js/crypto-js'

// 加密 这里使用的是CBC模式 必须要设置偏移量
export function encryptData(encryptData) {
    const data = CryptoJS.enc.Utf8.parse(JSON.stringify(encryptData)) // 转义一下数据
    const key = CryptoJS.enc.Utf8.parse('3024704570548226') // 秘钥 需要设置16位
    const iv = CryptoJS.enc.Utf8.parse('3024704570548226') // 偏移量
    const encrypted = CryptoJS.AES.encrypt(data, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC
    })
    return CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
};
// 解密方法一：
export function decryptData(decryptStr, keyStr, ivStr) {
    const decryptBase64Str = CryptoJS.enc.Base64.parse(decryptStr)
    const key = CryptoJS.enc.Utf8.parse('3024704570548226') // 密钥 需要与加密秘钥保持一致
    const iv = CryptoJS.enc.Utf8.parse('3024704570548226') // 密钥偏移量
    const createCode = CryptoJS.lib.CipherParams.create({ ciphertext: decryptBase64Str }) // 将密文转换成 WordArray 对象
    const decryptedData = CryptoJS.AES.decrypt(createCode, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    })
    const decryptedStr = CryptoJS.enc.Utf8.stringify(decryptedData).toString()
    return decryptedStr
};
// 解密方法二：
export function decrypt(encryptedData) {
    const key = CryptoJS.enc.Utf8.parse('3024704570548226')
    const iv = CryptoJS.enc.Utf8.parse('3024704570548226')
    const decrypted = CryptoJS.AES.decrypt(encryptedData, key,
        {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        })
    return decrypted.toString(CryptoJS.enc.Utf8)
}
