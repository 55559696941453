<template>
  <div class="login">
    <div class="login-form" >
      <div align="center" class="login-icon">
        <svg-icon name="user" style="color: #e34545; width:70px;height:70px;margin-bottom: 30px"></svg-icon>
      </div>
        <el-tabs v-model="activeName" @tab-click="handleClick" >
            <el-tab-pane label="个人用户" name="one" >
              <el-form :label-position="labelPosition" label-width="80px" :model="user" ref="user" :rules="dataRule">
                <el-form-item class="mb-2" prop="tel">
                  <template>
                    <div class="font-size-18 font-weight-200">个人账号</div>
                    <el-input placeholder="手机号" v-model="user.tel"></el-input>
                  </template>
                </el-form-item>
                <el-form-item prop="password">
                  <template >
                    <div class="font-size-18 font-weight-200">密码</div>
                    <el-input type="password" placeholder="请输入密码" v-model="user.password"></el-input>
                  </template>
                </el-form-item>
                <el-form-item prop="captcha">
                  <div class="font-size-18 font-weight-200">验证码</div>
                  <el-row :gutter="20">
                    <el-col :span="10">
                      <el-input v-model="user.captcha" placeholder="验证码">
                      </el-input>
                    </el-col>
                    <el-col :span="14" class="login-captcha">
                      <img :src="captchaPath" @click="getCaptcha()" alt="">
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-form>
            </el-tab-pane>
             <el-tab-pane label="企业用户" name="two" >
              <el-form :label-position="labelPosition" label-width="80px" :model="user" ref="user" :rules="dataRule">
                <el-form-item class="mb-2" prop="tel">
                  <template>
                    <div class="font-size-18 font-weight-200">企业账号</div>
                    <el-input placeholder="手机号" v-model="user.tel"></el-input>
                  </template>
                </el-form-item>
                <el-form-item prop="password">
                  <template>
                    <div class="font-size-18 font-weight-200">密码</div>
                    <el-input type="password" placeholder="请输入密码" v-model="user.password"></el-input>
                  </template>
                </el-form-item>
                <el-form-item prop="captcha">
                  <div class="font-size-18 font-weight-200">验证码</div>
                  <el-row :gutter="20">
                    <el-col :span="10">
                      <el-input v-model="user.captcha" placeholder="验证码">
                      </el-input>
                    </el-col>
                    <el-col :span="14" class="login-captcha">
                      <img :src="captchaPath" @click="getCaptcha()" alt="">
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-form>
             </el-tab-pane>
          </el-tabs>
      <el-button class="login-btn" type="danger" @click="login('user')">立即登录</el-button>
      <div class="mt-3">
        还不是会员？
        <router-link to="/register">立即注册</router-link>
        <div class="ForgotPassword" @click="goForgotPassword()">忘记密码</div>
      </div>
    </div>
    <el-dialog
        title="您首次登录或者距离上次修改密码已过90天"
        :visible.sync="dialogVisible"
        width="30%"
        >
      <i class="el-icon-warning redColor"></i>
      <span class="redColor" >请先修改密码！</span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="danger" @click="goForgotPassword()">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {mapActions} from "vuex";
  import { getUUID } from '@/utils'
import { encryptData, decrypt } from '../utils/crypto'

export default {
  data() {
    var checkphone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else if (!this.isCellPhone(value)) {
        callback(new Error('请输入正确的手机号!'))
      } else {
        callback()
      }
    }
    return {
      loadding:false,
      activeName: 'one',
      dialogVisible: false,
      labelPosition: 'top',
      captchaPath: '',
      user: {
        tel: '',
        password: '',
        uuid: '',
        captcha: '',
        isloading: 0

      },
      dataRule: {
        tel: [
          {required: true, validator: checkphone, trigger: 'blur'}
        ],
        password: [
          {required: true, message: '密码不能为空', trigger: 'blur'}
        ],
          captcha: [
            { required: true, message: '验证码不能为空', trigger: 'blur' }
          ]
      },
    }
  },
    created () {
      this.getCaptcha()
    },
  mounted() {

  },
  methods: {
    ...mapActions(["setUser", "setToken","setShowLogin", "setShoppingCart"]),
     // 获取验证码
      getCaptcha () {

      this.user.uuid = getUUID()
      this.captchaPath = this.$target +`auth/captcha?uuid=${this.user.uuid}`
        console.log(this.captchaPath)
    },
    //忘记密码
    goForgotPassword() {
      this.dialogVisible = false;
      this.$router.push({
        path: '/ForgotPassword'
      })
    },
    handleClick(e){
      if(e.name =='one'){
      this.user.isloading = 0
      }else{
      this.user.isloading = 1
      }
    },
    login(user) {
      this.$refs[user].validate((valid) => {
        if (valid) {
          // console.log("string",JSON.stringify(this.user));
          // console.log("加密",encryptData(JSON.stringify(this.user)));
          this.$post('/shoppingui/alogin/memberlogin', {
            "param": encryptData(JSON.stringify(this.user))
          }).then((data) => {
            if (data.code == 0) {
                this.setUser(data.data.userinfo);
                this.setToken(data.data.token);
                // localStorage.setItem("token", data.data.token);
                localStorage.setItem('token', data.data.token);
                localStorage.setItem('user', data.data.userinfo);
                localStorage.setItem('mytime', new Date().getTime());
                this.$router.push({path: '/'})
            } else if(data.code == '90'){
              this.$message({
                message: '90:'+data.message,
                type: 'error',
                showClose: 'true',
                onclose:()=>{
                }
              })

            }else {
              this.$message({
                message: '2001:'+data.message,
                type: 'error',
                showClose: 'true',
                onclose:()=>{
                }
              })
            }
            this.getCaptcha()
          })
        }
      });
    },
    // 检查手机号
    isCellPhone(val) {
      if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(val)) {
        return false
      } else {
        return true
      }
    },
    //入例框校验共同方法
    check() {
      let flag;
      this.$refs['requestData'].validate((valid) => {
        flag = valid
      })
      return flag
    },
  },
}
</script>

<style scoped lang="scss">
.redColor {
  color: red;
  font-size: 30px;
}
.login{
  width: 100%;
  min-height: 606px;
  background: url("../assets/imgs/bg-new.png");
  margin: 5px 0px;
  overflow-x: hidden;
}
.login-form{
  width: 390px;
  background: whitesmoke;
  padding: 40px 40px;
  float: right;
  margin-top: 5%;
  margin-right: 15%;
}
.login-btn{
  width: 100%;
}
.ForgotPassword {
  float: right;

}
.ForgotPassword:hover {
  cursor: pointer;
  color:#e34545;
}

/deep/.el-tabs {
  user-select: none;
}
/deep/.el-tabs .el-tabs__item{
  font-size:22px !important;
}
</style>
